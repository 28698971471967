<template>
  <div class="app-body">
    <div class="a-flex-rsbc a-ptb-15">
      <span class="a-fs-16 a-fw-b">员工</span>
      <div class="a-flex-rcc">
        <le-export-btn :page-param="pageParam"></le-export-btn>
        <el-button
          icon="el-icon-plus"
          type="primary"
          class="s-btn-add a-ml-24"
          @click="handlerAddProject"
        >
          员工
        </el-button>
      </div>
    </div>
    <el-card class="el-main">
      <le-search-form id="leSearch" @reset="handlerRest" @search="handlerSearch">
        <le-input-icon-search
          v-model="pageParam.params.userName"
          label="员工姓名"
          placeholder="请输入员工姓名"
        />
        <le-input-icon-search
          v-model="pageParam.params.mobile"
          type="number"
          label="手机号"
          placeholder="请输入手机号"
        />
        <div class="flex_start mgl24">
          <p class="font14 a-ff-pfr a-c-normal">所属商户</p>
          <le-company-under-select
            v-model="pageParam.params.companyId"
            @input="companyBack"
          ></le-company-under-select>
        </div>
      </le-search-form>
      <le-pagview :page-param="pageParam" @setData="setTableData">
        <el-table
          v-sticky="{ top: 0, parent: '.el-card__body' }"
          :data="tableData"
          :highlight-current-row="true"
        >
          <el-table-column prop="userName" label="姓名" min-width="100"></el-table-column>
          <el-table-column prop="mobile" label="电话" min-width="100"></el-table-column>
          <el-table-column prop="companyName" label="商户" min-width="150"></el-table-column>
          <el-table-column label="创建时间" width="200">
            <template slot-scope="{ row }">
              <span>{{ row.createTimeText || "" }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="isValid" label="账号启用/禁用" width="180">
            <template slot-scope="{ row }">
              <div class="flex_start mgl32">
                <le-switch
                  v-model="row.isValid"
                  :ac-value="1"
                  :inac-value="0"
                  inac-color="#B9B9B9"
                  @input="setCompanyUserValid(row)"
                ></le-switch>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="name" label="操作" width="180" fixed="right">
            <template slot-scope="scope">
              <el-tooltip class="item" effect="dark" content="详情" placement="top">
                <img
                  src="../../assets/icon/option-detail.png"
                  class="a-wh-16 mgr20"
                  @click="toFranchiseeDetail(scope.row)"
                />
              </el-tooltip>
              <el-tooltip class="item" effect="dark" content="修改" placement="top">
                <img
                  src="../../assets/icon/edit.png"
                  class="a-wh-16 mgr20"
                  @click="handlerChange(scope.row)"
                />
              </el-tooltip>
              <el-tooltip class="item a-ml-12" effect="dark" content="更多操作" placement="top">
                <el-dropdown placement="bottom-start" @command="handlerOperationDev">
                  <img src="@/assets/icon/more-operation.png" class="a-wh-16" />
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item
                      v-for="item in optionsMoreOperation"
                      :key="item.value"
                      :command="item.value + '#' + scope.$index"
                      :value="item.value"
                    >
                      {{ item.label }}
                    </el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
              </el-tooltip>
            </template>
          </el-table-column>
        </el-table>
        <template slot="footerLabel" slot-scope="scope">
          <div v-if="scope.val != -1" class="a-flex-rfsc">
            <span class="a-fs-12 a-c-normal">共</span>
            <span class="a-fs-16 a-c-master a-fw-b a-plr-7">{{ scope.val }}</span>
            <span class="a-fs-12 a-c-normal">名员工</span>
          </div>
        </template>
      </le-pagview>
    </el-card>
    <el-dialog title="提示" :visible.sync="revokeShow" width="30%">
      <div class="flex_start">
        <i class="fontC9 el-icon-info font18 mgr6"></i>
        <span class="fontC3 font14">确定删除该员工？</span>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="revokeShow = false">取 消</el-button>
        <el-button type="primary" @click="cancelSignAgency">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog title="提示" :visible.sync="changeShow" width="30%">
      <div>
        <el-form ref="pwdForm" :model="pwdForm" :rules="rules">
          <el-form-item label="新密码" prop="password" label-width="150">
            <el-input v-model="pwdForm.password" type="password"></el-input>
          </el-form-item>
          <el-form-item label="新密码确认" prop="password_two" label-width="150">
            <el-input v-model="pwdForm.password_two" type="password"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="changeShow = false">取 消</el-button>
        <el-button type="primary" @click="cancelChangePwd">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
let _this;
import { mapState } from "vuex";

export default {
  name: "StaffManage",

  data() {
    const validatepwd = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请确认密码"));
      } else if (value != _this.pwdForm.password) {
        callback(new Error("两次密码输入不同"));
      } else {
        callback();
      }
    };
    return {
      pageParam: {
        url: this.$Config.apiUrl.getCompanyUser,
        method: "post",
        params: {
          userName: "",
          mobile: "", //加盟商名称
          companyId: null, //所属商户
        },
        freshCtrl: 1,
      },
      optionsMoreOperation: [
        {
          label: "修改密码",
          value: 1,
        },
        {
          label: "删除员工",
          value: 2,
        },
      ],
      tableData: [], //数据列表
      revokeShow: false,
      handlerData: "", //操作数据
      changeShow: false, //修改密码
      pwdForm: {
        password: "",
        password_two: "",
      },
      rules: {
        password: [
          {
            required: true,
            message: "请输入新密码",
            trigger: "blur",
          },
        ],
        password_two: [
          {
            required: true,
            validator: validatepwd,
            trigger: "blur",
          },
        ],
      },
    };
  },
  computed: {
    ...mapState({
      company: (state) => state.company.company,
    }),
  },
  created() {
    _this = this;
    this.pageParam.params.companyId = this.company.id;
  },
  methods: {
    //获取列表
    setTableData(data) {
      this.tableData = data;
    },
    //确认筛选
    handlerSearch() {
      this.pageParam.freshCtrl++;
    },
    //重置筛选表单
    handlerRest() {
      this.pageParam.params = {
        userName: "",
        mobile: "", //加盟商名称
        companyId: null, //所属商户
      };
      this.pageParam.freshCtrl++;
    },
    //启用或禁用
    setCompanyUserValid(val) {
      this.$Axios
        ._get({
          url: this.$Config.apiUrl.setCompanyUserValid,
          params: {
            userId: val.userId,
            companyId: val.companyId,
            valid: val.isValid,
          },
        })
        .then((res) => {
          if (res.result.code == 0) {
            if (val.isValid) {
              this.$message.success("启用成功!");
            } else {
              this.$message.success("禁用成功!");
            }
          } else {
            this.$message.error(res.result.message);
          }
        });
    },
    // 更多操作
    handlerOperationDev(val) {
      const command = val.split("#")[0];
      const index = val.split("#")[1];
      this.handlerData = this.tableData[index];
      if (command == 1) {
        //修改密码
        this.changeShow = true;
      } else if (command == 2) {
        //删除员工
        this.revokeShow = true;
      }
    },
    //删除员工
    cancelSignAgency() {
      this.$Axios
        ._get({
          url: this.$Config.apiUrl.delCompanyUser,
          params: {
            userId: this.handlerData.userId,
            companyId: this.handlerData.companyId,
          },
        })
        .then((res) => {
          if (res.result.code == 0) {
            this.$message.success("删除成功!");
            this.revokeShow = false;
            this.handlerData = "";
          } else {
            this.$message.error(res.result.message);
          }
          this.pageParam.freshCtrl++;
        });
    },
    //修改密码
    cancelChangePwd() {
      this.$refs["pwdForm"].validate(async (valid) => {
        if (valid) {
          this.$Axios
            ._get({
              url: this.$Config.apiUrl.resetUserPassword,
              params: {
                userId: this.handlerData.userId,
                companyId: this.handlerData.companyId,
                password: this.pwdForm.password,
              },
            })
            .then((res) => {
              if (res.result.code == 0) {
                this.$message.success("修改成功!");
                this.changeShow = false;
                this.handlerData = "";
              } else {
                this.$message.error(res.result.message);
              }
            });
        }
      });
    },
    companyBack() {},
    //跳转详情
    toFranchiseeDetail(datas) {
      this.$router.push({
        path: `/staffManage/staff-detail?id=${datas.id}&userId=${datas.userId}&companyId=${datas.companyId}`,
      });
    },
    //添加员工
    handlerAddProject() {
      this.$router.push({
        path: "/staffManage/staff-add",
      });
    },
    //编辑员工
    handlerChange(datas) {
      this.$router.push({
        path: `/staffManage/staff-exit?id=${datas.id}&userId=${datas.userId}&companyId=${datas.companyId}`,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.mgl150 {
  margin-left: 150px;
}
</style>
